<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create About Us Content</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="postContent" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="card" style="height: 25vh">
                                                    <div v-if="image.length">
                                                        <div class="progress" style="height: 7px;">
                                                            <div class="progress-bar progress-bar-striped" :class="{'progress-bar-animated': image[0].active, 'w-50 bg-current' : image[0].active, 'w-100 bg-current': image[0].success, 'w-100 bg-danger' : image[0].error}" role="progressbar" :style="{width: image[0].progress + '%'}"></div>
                                                        </div>
                                                    </div>
                                                    <div class="text-center py-5">
                                                        <ul v-if="image.length">
                                                            <li>
                                                                <span>{{image[0].name}} - {{image[0].size | sizeFormat}}</span> -
                                                                <span class="badge badge-danger text-capitalize" v-if="image[0].error">{{image[0].error}}</span>
                                                                <span class="badge badge-success" v-else-if="image[0].success"><i class="fas fa-check"></i></span>
                                                                <div v-else-if="image[0].active" class="spinner-border spinner-border-sm text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                <span v-else></span>
                                                            </li>
                                                        </ul>
                                                        <h4 v-else>Input your Thumbnail !</h4>
                                                        <div class="m-auto">
                                                            <file-upload
                                                            input-id="image"
                                                            name="image"
                                                            class="btn btn-current mr-1"
                                                            @input="updateThumb"
                                                            @input-file="inputThumb"
                                                            @input-filter="thumbFilter"
                                                            :custom-action="thumbAction"
                                                            accept="image/jpg,image/png"
                                                            ref="image">
                                                            <i class="fa fa-plus"></i>
                                                            Select files
                                                            </file-upload>

                                                            <button type="button" class="btn btn-danger ml-1"  v-if="!$refs.image || $refs.image.active" @click.prevent="$refs.image.active = false">
                                                            <i class="fa fa-stop" aria-hidden="true"></i>
                                                            Stop Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="card" style="height: 25vh">
                                                    <div v-if="video.length">
                                                        <div class="progress" style="height: 7px;">
                                                            <div class="progress-bar progress-bar-striped" :class="{'progress-bar-animated': video[0].active, 'w-50 bg-current' : video[0].active, 'w-100 bg-current': video[0].success, 'w-100 bg-danger' : video[0].error}" role="progressbar" :style="{width: video[0].progress + '%'}"></div>
                                                        </div>
                                                    </div>
                                                    <div class="text-center py-5">
                                                        <ul v-if="video.length">
                                                            <li>
                                                                <span>{{video[0].name}} - {{video[0].size | sizeFormat}}</span> -
                                                                <span class="badge badge-danger text-capitalize" v-if="video[0].error">{{video[0].error}}</span>
                                                                <span class="badge badge-success" v-else-if="video[0].success"><i class="fas fa-check"></i></span>
                                                                <div v-else-if="video[0].active" class="spinner-border spinner-border-sm text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                <span v-else></span>
                                                            </li>
                                                        </ul>
                                                        <h4 v-else>Input your file !</h4>
                                                        <div class="m-auto">
                                                            <file-upload
                                                            class="btn btn-current mr-1"
                                                            @input="updateFile"
                                                            @input-file="inputFile"
                                                            @input-filter="fileFilter"
                                                            :custom-action="fileAction"
                                                            accept="video/mp4"
                                                            ref="video">
                                                            <i class="fa fa-plus"></i>
                                                            Select files
                                                            </file-upload>

                                                            <button type="button" class="btn btn-danger ml-1"  v-if="!$refs.video || $refs.video.active" @click.prevent="$refs.video.active = false">
                                                            <i class="fa fa-stop" aria-hidden="true"></i>
                                                            Stop Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Title</label>
                                                <vue-editor v-model="form.title" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Text</label>
                                                <vue-editor v-model="form.text" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true
import FileUpload from 'vue-upload-component'
export default {
    components: {
        FileUpload,
    },
    data(){
        return {
            image: [],
            video: [],
            form : {
                title: '',
                text: '',
                image: '',
                video: '',
            }
        }
    },
    filters:{
        sizeFormat: function (size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        }
    },
    methods: {
        updateThumb(value) {
            this.image = value
        },
        updateFile(value) {
            this.video = value
        },
        async thumbAction(file) {
            return this.postThumb(file)
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputThumb(newFile, oldFile) {
            if (newFile && oldFile) {
                if (newFile.active !== oldFile.active) {
                    if (newFile.size > 2000 * 1024) {
                        newFile = this.$refs.image.update(newFile, {error: 'size'})
                    }
                }
            }

            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.image.active) {
                    this.$refs.image.active = true
                }
            }
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.video.active) {
                    this.$refs.video.active = true
                }
            }
        },
        thumbFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(png|jpg)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        fileFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(mp4)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'about-content');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.form.video = res.data.path
                return res.data
            })
        },
        async postThumb(file){
            let data = new FormData();
            data.append('dir', 'about-content');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.form.image = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                return res.data
            })
        },
        async postContent() {
            var data = {
                title: this.form.title,
                text: this.form.text,
                image: this.form.image,
                video: this.form.video,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/about/content`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Create Content!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsAboutAdmin' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Create Content!',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>